(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@didi/ec-base"), require("@didi/iconflow/antd"), require("@didi/pagelets-sdk"), require("axios"), require("dayjs"), require("lodash"), require("prop-types"), require("react"), require("react-dom"), require("single-spa-react"));
	else if(typeof define === 'function' && define.amd)
		define(["@didi/ec-base", "@didi/iconflow/antd", "@didi/pagelets-sdk", "axios", "dayjs", "lodash", "prop-types", "react", "react-dom", "single-spa-react"], factory);
	else if(typeof exports === 'object')
		exports["organization-graph"] = factory(require("@didi/ec-base"), require("@didi/iconflow/antd"), require("@didi/pagelets-sdk"), require("axios"), require("dayjs"), require("lodash"), require("prop-types"), require("react"), require("react-dom"), require("single-spa-react"));
	else
		root["organization-graph"] = factory(root["@didi/ec-base"], root["@didi/iconflow/antd"], root["@didi/pagelets-sdk"], root["axios"], root["dayjs"], root["lodash"], root["prop-types"], root["react"], root["react-dom"], root["single-spa-react"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__593__, __WEBPACK_EXTERNAL_MODULE__190__, __WEBPACK_EXTERNAL_MODULE__709__, __WEBPACK_EXTERNAL_MODULE__742__, __WEBPACK_EXTERNAL_MODULE__185__, __WEBPACK_EXTERNAL_MODULE__773__, __WEBPACK_EXTERNAL_MODULE__949__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__514__, __WEBPACK_EXTERNAL_MODULE__25__) => {
return 