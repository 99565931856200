import React from 'react';
import manifest from '../manifest.json';
import Template from '@didi/ehr-org-graph';

const Pagelet = props => {
  const {
    params
  } = props;

  const { setVisibleDetail, isTalent, cardJump, apis, apiHost, ...restParams } = params;

  return (
    <Template
      params={{ ...restParams, apis: apis || manifest.apis, apiHost: apiHost || manifest.apiHost }}
      setVisibleDetail={setVisibleDetail}
      isTalent={isTalent}
      cardJump={cardJump}
    />
  );
};

export default Pagelet;
